import { Container } from 'react-bootstrap';
import { Outlet } from 'react-router-dom';

import './styles.scss';

const Layout = () => {
    return (
        <div className={'layout'}>
            <header>
                <h1>{'Texture Generator'}</h1>
            </header>
            <main>
                <Container>
                    <Outlet />
                </Container>
            </main>
            <footer>
                <span>
                    {'Made with <3 by '}
                    <a href={'https://github.com/pamplona007'} target={'_blank'} rel={'noreferrer'}>
                        {'Lucas Pamplona'}
                    </a>
                </span>
            </footer>
        </div>
    );
};

export default Layout;