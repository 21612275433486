import { useRoutes } from 'react-router-dom';

import Layout from 'components/Layout';
import Checked from 'pages/checked';

export const routes = [
    {
        element: <Layout />,
        children: [
            {
                path: '/',
                element: <Checked />,
                exact: true,
                index: true,
            },
        ],
    },
];

const Routes = () => {
    const element = useRoutes(routes);

    return element;
};

export default Routes;