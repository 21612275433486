import { useContext, useLayoutEffect } from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { ChromePicker } from 'react-color';

import classNames from 'classnames';
import { RenderContext } from 'contexts/render';
import './styles.scss';

const possibleEdgesModes = [
    'none',
    'corner',
    'horizontal',
    'vertical',
    'both',
];

const Checked = () => {
    const {
        renderResult,
        renderConfig,
        setConfig,
        exportJPG,
        exportNormalMap,
    } = useContext(RenderContext);

    const {
        images,
        imageHeight,
        imageWidth,
        gridRows,
        gridColumns,
        gridColumnGap,
        gridRowGap,
        gridColor,
        normalMapMode,
        edgesMode,
    } = renderConfig;

    useLayoutEffect(() => {
        setConfig({
            imageHeight: 512,
            imageWidth: 512,
            gridRows: 4,
            gridColumns: 4,
            gridColumnGap: 5,
            gridRowGap: 5,
            gridColor: '#000000',
            normalMapMode: false,
            edgesMode: 'corner',
        });
    }, []);

    return (
        <Row>
            <Col sm={6}>
                <div className={'preview-wrapper'}>
                    <img
                        className={classNames('preview', { 'normal-map': normalMapMode })}
                        src={renderResult}
                    />
                </div>
            </Col>
            <Col sm={6}>
                <div className={'settings'}>
                    <Form>
                        <Row>
                            <Col md={6}>
                                <Form.Group controlId={'image-height'}>
                                    <Form.Label>{'Height'}</Form.Label>
                                    <Form.Control
                                        type={'number'}
                                        placeholder={'Enter height'}
                                        value={imageHeight}
                                        onChange={(e) => setConfig('imageHeight', e.target.value)}
                                        min={15}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId={'image-width'}>
                                    <Form.Label>{'Width'}</Form.Label>
                                    <Form.Control
                                        type={'number'}
                                        placeholder={'Enter width'}
                                        value={imageWidth}
                                        onChange={(e) => setConfig('imageWidth', e.target.value)}
                                        min={15}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId={'grid-rows'}>
                                    <Form.Label>{'Rows'}</Form.Label>
                                    <Form.Control
                                        type={'number'}
                                        placeholder={'Enter row quantity'}
                                        value={gridRows}
                                        onChange={(e) => setConfig('gridRows', e.target.value)}
                                        min={1}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId={'grid-columns'}>
                                    <Form.Label>{'Columns'}</Form.Label>
                                    <Form.Control
                                        type={'number'}
                                        placeholder={'Enter column quantity'}
                                        value={gridColumns}
                                        onChange={(e) => setConfig('gridColumns', e.target.value)}
                                        min={1}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId={'grid-row-gap'}>
                                    <Form.Label>{'Row Gap'}</Form.Label>
                                    <Form.Control
                                        type={'number'}
                                        placeholder={'Enter row gap'}
                                        value={gridRowGap}
                                        onChange={(e) => setConfig('gridRowGap', e.target.value)}
                                        min={0}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId={'grid-column-gap'}>
                                    <Form.Label>{'Column Gap'}</Form.Label>
                                    <Form.Control
                                        type={'number'}
                                        placeholder={'Enter column gap'}
                                        value={gridColumnGap}
                                        onChange={(e) => setConfig('gridColumnGap', e.target.value)}
                                        min={0}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId={'edge-mode'}>
                                    <Form.Label>{'Edges Mode'}</Form.Label>
                                    <Form.Select
                                        value={edgesMode}
                                        onChange={(e) => setConfig('edgesMode', e.target.value)}
                                    >
                                        {possibleEdgesModes.map((mode) => (
                                            <option key={mode} value={mode}>
                                                {mode}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Form.Group>
                                <ChromePicker
                                    color={gridColor}
                                    onChange={(color) => setConfig('gridColor', color.hex)}
                                />
                            </Col>
                            <Col md={6}>
                                <Form.Group controlId={'normal-map-mode'}>
                                    <Form.Check
                                        type={'checkbox'}
                                        label={'Display normal map'}
                                        checked={normalMapMode}
                                        onChange={(e) => setConfig('normalMapMode', e.target.checked)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col xs={12}>
                                {images?.map(({ file, object }, index) => (
                                    <div key={index}>
                                        {file?.name || object}
                                        <Button
                                            onClick={() => {
                                                const newImages = [...images];
                                                newImages.splice(index, 1);
                                                setConfig('images', newImages);
                                            }}
                                        >
                                            {'Remove'}
                                        </Button>
                                    </div>
                                ))}
                            </Col>
                            <Col xs={12}>
                                <Form.Group controlId={'image-upload'}>
                                    <Form.Control
                                        type={'file'}
                                        value={''}
                                        multiple
                                        onChange={(event) => {
                                            const files = Array.from(event.target.files);
                                            const newImages = images ? [...images] : [];

                                            files.forEach((file) => {
                                                newImages.push({
                                                    file,
                                                    object: URL.createObjectURL(file),
                                                });
                                            });

                                            setConfig('images', newImages);
                                        }}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                    </Form>
                    <Row>
                        <Col>
                            <Button onClick={exportJPG}>
                                {'Export'}
                            </Button>
                            <Button onClick={exportNormalMap}>
                                {'Export normal map'}
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Col>
        </Row>
    );
};

export default Checked;