import React, { forwardRef, useMemo } from 'react';

import classNames from 'classnames';

import './styles.scss';

const CheckedTexture = (props, ref) => {
    const {
        images,
        imageHeight,
        imageWidth,
        gridRows,
        gridColumns,
        gridColumnGap,
        gridRowGap,
        gridColor,
        normalMapMode,
        edgesMode,
    } = props;

    const gridSlotQuantity = useMemo(() => gridRows * gridColumns, [gridRows, gridColumns]);

    return (
        <div
            className={classNames(
                'checked-render',
                {
                    'normal-map': normalMapMode,
                    [`edges-${edgesMode}`]: edgesMode,
                },
            )}
            ref={ref}
            style={{
                '--image-height': imageHeight,
                '--image-width': imageWidth,
                '--image-height-px': `${imageHeight}px`,
                '--image-width-px': `${imageWidth}px`,
                '--grid-rows': gridRows,
                '--grid-columns': gridColumns,
                '--grid-row-gap': `${gridRowGap}px`,
                '--grid-column-gap': `${gridColumnGap}px`,
                '--grid-color': gridColor,
            }}
        >
            {gridSlotQuantity && [...Array(gridSlotQuantity)].map((_, index) => {
                const image = images ? images[index % images.length] : null;

                return (
                    <div
                        key={index}
                        className={'slot'}
                        style={{
                            '--image': image ? `url(${image?.object})` : 'none',
                        }}
                    />
                );
            })}
        </div>

    );
};

export default forwardRef(CheckedTexture);