import { BrowserRouter } from 'react-router-dom';

import RenderStorage from 'contexts/render';

import Routes from './routes';

const App = () => {
    return (
        <RenderStorage>
            <BrowserRouter>
                <Routes />
            </BrowserRouter>
        </RenderStorage>
    );
};

export default App;
