import React, { createContext, useCallback, useEffect, useRef, useState } from 'react';

import CheckedTexture from 'components/_textures/CheckedTexture';
import DomToImage from 'dom-to-image';
import throttle from 'lodash/throttle';

export const RenderContext = createContext({});

const RenderStorage = ({ children }) => {
    const [isRendering, setIsRendering] = useState(false);
    const [renderResult, setRenderResult] = useState(null);
    const [renderConfig, setRenderConfig] = useState({});

    const canvas = useRef(null);

    const download = (dataUrl, filename = 'texture.jpg') => {
        const link = document.createElement('a');
        link.download = filename;
        link.href = dataUrl;
        link.click();
    };

    const exportJPG = async () => {
        canvas.current.classList.add('exporting');

        const dataUrl = await DomToImage.toJpeg(canvas.current, {
            quality: 0.95,
        });

        canvas.current.classList.remove('exporting');

        download(dataUrl, '_difuse.jpg');
    };

    const exportNormalMap = async () => {
        canvas.current.classList.add('exporting');
        canvas.current.classList.add('normal-map');

        const dataUrl = await DomToImage.toJpeg(canvas.current, {
            quality: 0.95,
        });

        canvas.current.classList.remove('exporting');

        if (!renderConfig.normalMapMode) {
            canvas.current.classList.remove('normal-map');
        }

        download(dataUrl, '_normal.jpg');
    };

    const setConfig = (config, value) => {
        if ('object' === typeof config) {
            setRenderConfig({
                ...renderConfig,
                ...config,
            });
            return;
        }

        setRenderConfig({
            ...renderConfig,
            [config]: value,
        });
    };

    const render = useCallback(throttle(async () => {
        const timeout = setTimeout(() => {
            setIsRendering(true);
        }, 250);

        const result = await DomToImage.toBlob(canvas.current);

        clearTimeout(timeout);
        setIsRendering(false);

        setRenderResult(URL.createObjectURL(result));
    }, 200), []);

    useEffect(() => {
        if (!renderConfig) {
            return;
        }

        render();
    }, [renderConfig]);

    return (
        <RenderContext.Provider
            value={{
                renderResult,
                isRendering,
                setIsRendering,
                exportJPG,
                exportNormalMap,
                renderConfig,
                setConfig,
            }}
        >
            {children}
            <CheckedTexture
                {...renderConfig}
                ref={canvas}
            />
        </RenderContext.Provider>
    );
};

export default RenderStorage;